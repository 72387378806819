import React from "react";
import styled, { css } from "styled-components";
import { FONT_STYLE, SPACING, COLOR, DEVICE, BAGS, BAGSPNG } from "../constants.js"
import data from "../content.json";

const Position = {
    generalDebt: ["0", "2vw"],
    generalDebtMax: ["0", "2vw"],
    creditCard: ["4.5vw", "2vw"],
    creditCardMax: ["4.5vw", "2vw"],
    studentLoan: ["9vw", "2.25vw"],
    studentLoanMax: ["9vw", "2.25vw"],
    mortgage: ["13.5vw", "1.5vw"],
    mortgageMax: ["13.5vw", "1.5vw"]
}

const ProgressBagsContainer = styled.div`
    position: absolute;
    z-index: 100;
    width: 16.5vw;
    height: 10vw;
    top: auto;
    left: 50%;
    margin-left: -8.25vw;
    bottom: 0;
    
    // @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
    //     top: 780px;
    //     left: 185px;
    //     width: 260px;
    //     height: 150px;
    // }
`

const BagContainer = styled.div`
    position: absolute;

    ${props=>props.current==="generalDebt" && css`
        left: ${Position.generalDebt[0]};
        top: ${Position.generalDebt[1]};
    `}

    ${props=>props.current==="creditCard" && css`
        left: ${Position.creditCard[0]};
        top: ${Position.creditCard[1]};
    `}

    ${props=>props.current==="studentLoan" && css`
        left: ${Position.studentLoan[0]};
        top: ${Position.studentLoan[1]};
    `}

    ${props=>props.current==="mortgage" && css`
        left: ${Position.mortgage[0]};
        top: ${Position.mortgage[1]};
    `}
`

const Bag = styled.div`
    width: 4vw;
    height: 4vw;
    margin-bottom: ${SPACING.SMALL}

    @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
        width: ${SPACING.XLARGE};
        height: ${SPACING.XLARGE};
    }

    /* Stack of 3 bags */
    
    ${props=>props.bagKey === 1 && css` transform: rotate(11deg); `}
    ${props=>props.bagKey === 2 && css` transform: rotate(-5deg); `}

    ${props=>props.current === "generalDebt" && css`
        background: url(${BAGSPNG[0]}) no-repeat center;
        background: url(${BAGS[0]}) no-repeat center, none;
        background-size: auto 100%;

        ${props=>props.bagKey === 0 && css`
            transform: rotate(-18deg);
        `}
    `}

    ${props=>props.current === "creditCard" && css`
        background: url(${BAGSPNG[1]}) no-repeat center;
        background: url(${BAGS[1]}) no-repeat center, none;
        background-size: auto 100%;

        ${props=>props.bagKey === 0 && css`
            transform: rotate(11deg);
        `}
    `}

    ${props=>props.current === "studentLoan" && css`
        background: url(${BAGSPNG[2]}) no-repeat center;
        background: url(${BAGS[2]}) no-repeat center, none;
        background-size: auto 100%;

        ${props=>props.bagKey === 0 && css`
            transform: rotate(-8deg);
        `}
    `}

    ${props=>props.current === "mortgage" && css`
        background: url(${BAGSPNG[3]}) no-repeat center;
        background: url(${BAGS[3]}) no-repeat center, none;
        background-size: auto 100%;

        ${props=>props.bagKey === 0 && css`
            transform: rotate(0);
        `}
    `}

    /* Attached */

    ${props=>props.attached && css`
        position: absolute;
    `}

    ${props=>props.attached==="generalDebt" && css`
        transform: rotate(-18deg);
        background: url(${BAGSPNG[0]}) no-repeat center;
        background: url(${BAGS[0]}) no-repeat center, none;
        background-size: auto 100%;
        left: ${Position.generalDebt[0]};
        top: ${Position.generalDebt[1]};

        @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
            left: ${Position.generalDebtMax[0]};;
            top: ${Position.generalDebtMax[1]};;
        }
    `}
    
    ${props=>props.attached==="creditCard" && css`
        transform: rotate(11deg);
        background: url(${BAGSPNG[1]}) no-repeat center;
        background: url(${BAGS[1]}) no-repeat center, none;
        background-size: auto 100%;
        position: absolute;
        left: ${Position.creditCard[0]};
        top: ${Position.creditCard[1]};

        @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
            left: ${Position.creditCardMax[0]};
            top: ${Position.creditCardMax[1]};
        }
    `}
    
    ${props=>props.attached==="studentLoan" && css`
        transform: rotate(-8deg);
        background: url(${BAGSPNG[2]}) no-repeat center;
        background: url(${BAGS[2]}) no-repeat center, none;
        background-size: auto 100%;
        position: absolute;
        left: ${Position.studentLoan[0]};
        top: ${Position.studentLoan[1]};

        @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
            left: ${Position.studentLoanMax[0]};
            top: ${Position.studentLoanMax[1]};
        }
    `}

    ${props=>props.attached==="mortgage" && css`
        background: url(${BAGSPNG[3]}) no-repeat center;
        background: url(${BAGS[3]}) no-repeat center, none;
        background-size: auto 100%;
        position: absolute;
        left: ${Position.mortgage[0]};
        top: ${Position.mortgage[1]};

        @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
            left: ${Position.mortgageMax[0]};
            top: ${Position.mortgageMax[1]};
        }
    `}
`

const AttachedBags = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`


const Debt = styled.div`
    ${FONT_STYLE.BODY}
    line-height: 1.2em;
    color: ${COLOR.PRIMARY};
    text-align: left;
    opacity: 0.5;
    top: -10px;
    position: absolute;
    left: -55px;
    width: 50px;
    height: ${SPACING.LARGE};
    transform: rotate(18deg);

    @media screen and (min-width: ${DEVICE.LAPTOP}){
        height: ${SPACING.XLARGE}
    }
`

class ProgressBags extends React.Component {
    render(){
        return(
            <ProgressBagsContainer current={this.props.current}>
                <AttachedBags>
                    {
                        this.props.current === "intro" && 
                        <Bag attached="generalDebt">
                            <Debt>{this.props.debts.length === 1 && data.shared.debts[0]}</Debt>
                        </Bag>
                    }
                    {
                        this.props.checkDebts("creditCard") && 
                        this.props.current !== "creditCard" && 
                        this.props.creditCard === 0 && 
                        <Bag attached={"creditCard"}/>
                    }
                    {
                        this.props.checkDebts("studentLoan") && 
                        this.props.current !== "studentLoan" && 
                        this.props.studentLoan  === 0 &&
                        <Bag attached={"studentLoan"}/>
                    }
                    {
                        this.props.checkDebts("mortgage") && 
                        this.props.current !== "mortgage" && 
                        this.props.mortgage  === 0 &&
                        <Bag attached={"mortgage"}/>
                    }
                </AttachedBags>
                {
                    this.props.current !== "intro" &&
                    <BagContainer current={this.props.current}>
                      {this.props[this.props.current] <= 2 &&
                        <Bag
                          onClick={() => {
                            const index = 2;
                            this.props.updateClickstream(null, `${data[this.props.current].name} Bag ${index + 1} - ${data[this.props.current].clouds[index].p.join(' ')}`);
                          }}
                          current={this.props.current}
                          bagKey={0}
                        />
                      }
                      {this.props[this.props.current] <= 1 &&
                        <Bag
                          onClick={() => {
                            const index = 1;
                            this.props.updateClickstream(null, `${data[this.props.current].name} Bag ${index + 1} - ${data[this.props.current].clouds[index].p.join(' ')}`);
                          }}
                          current={this.props.current}
                          bagKey={1}
                        />
                      }
                      {this.props[this.props.current] <= 0 &&
                        <Bag
                          onClick={() => {
                            const index = 0;
                            this.props.updateClickstream(null, `${data[this.props.current].name} Bag ${index + 1} - ${data[this.props.current].clouds[index].p.join(' ')}`);
                          }}
                          current={this.props.current}
                          bagKey={2}
                        />
                      }
                    </BagContainer>
                }
            </ProgressBagsContainer>
        )
    }
}

export default ProgressBags;