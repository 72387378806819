import { css, keyframes } from "styled-components";

import Character1 from "./img/female-blue-v3.svg";
import Character2 from "./img/male-yellow-v3.svg";
import Character3 from "./img/male-green-v3.svg";
import Character4 from "./img/female-red-v3.svg";

import Character1PNG from "./img/female-blue-v3@2x.png";
import Character2PNG from "./img/male-yellow-v3@2x.png";
import Character3PNG from "./img/male-green-v3@2x.png";
import Character4PNG from "./img/female-red-v3@2x.png";

import generalDebtBagPNG from "./img/debt-general-v4@2x.png";
import creditCardBagPNG from "./img/debt-credit-v4@2x.png";
import studentLoanBagPNG from "./img/debt-student-v4@2x.png";
import mortgageBagPNG from "./img/debt-mortgage-v4@2x.png";

import generalDebtBag from "./img/debt-general-v4.svg";
import creditCardBag from "./img/debt-credit-v4.svg";
import studentLoanBag from "./img/debt-student-v4.svg";
import mortgageBag from "./img/debt-mortgage-v4.svg";

import BalloonDefaultPNG from "./img/balloon-v3@2x.png";
import BalloonShadowPNG from "./img/shadow-v4@2x.png";
import BalloonSpikePNG from "./img/spike-v4@2x.png";

import BalloonDefault from "./img/balloon-v3.svg";
import BalloonShadow from "./img/shadow-v4.svg";
import BalloonSpike from "./img/spike-v4.svg";

import CloudAPNG from "./img/cloud-a-v3@2x.png";
import CloudASVG from "./img/cloud-a-v3.svg";
import CloudBPNG from "./img/cloud-b-v3@2x.png";
import CloudBSVG from "./img/cloud-b-v3.svg";
import CloudCPNG from "./img/cloud-c-v3@2x.png";
import CloudCSVG from "./img/cloud-c-v3.svg";
import CloudC2PNG from "./img/cloud-c2-v3@2x.png";
import CloudC2SVG from "./img/cloud-c2-v3.svg";
import CloudDPNG from "./img/cloud-d-v3@2x.png";
import CloudDSVG from "./img/cloud-d-v3.svg";
import CloudD2PNG from "./img/cloud-d2-v3@2x.png";
import CloudD2SVG from "./img/cloud-d2-v3.svg";
import CloudD3PNG from "./img/cloud-d3-v3@2x.png";
import CloudD3SVG from "./img/cloud-d3-v3.svg";

import SpritePNG from "./img/sprite-v4.png";
import SpriteSVG from "./img/sprite-v4.svg";

import MobileAnimation from "./img/mobile-animation.gif";

export const CHARACTERS = [Character1, Character2, Character3, Character4];

export const CHARACTERSPNG = [
  Character1PNG,
  Character2PNG,
  Character3PNG,
  Character4PNG
];

export const BAGS = [
  generalDebtBag,
  creditCardBag,
  studentLoanBag,
  mortgageBag
];

export const BAGSPNG = [
  generalDebtBagPNG,
  creditCardBagPNG,
  studentLoanBagPNG,
  mortgageBagPNG
];

export const BALLOONS = [BalloonDefault, BalloonShadow, BalloonSpike];

export const BALLOONSPNG = [
  BalloonDefaultPNG,
  BalloonShadowPNG,
  BalloonSpikePNG
];

export const SPRITE = {
  CHEVRON: css`
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 4px
            width: 10px;
            height: 16px;
            background: url(${SpritePNG}) no-repeat -144px -0px;
            background: url(${SpriteSVG}) no-repeat -144px -0px, none;
            margin: 0 0 0 0.2em;
            transition: all 0.3s ease;
            transform: translate(75%, 0) scale(0.75);

            @media all and (-ms-high-contrast:none) {
                margin: 0 0 -0.1em 0.2em; /* IE10 */
                
                *::-ms-backdrop { 
                    margin: 0 0 -0.1em 0.2em;
                } /* IE11 */
           }
        `,
  CLOSE: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 26px;
    height: 26px;
    background: url(${SpritePNG}) no-repeat -116px -0px;
    background: url(${SpriteSVG}) no-repeat -116px -0px, none;
  `,
  LOGO: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 115px;
    height: 42px;
    background: url(${SpritePNG}) no-repeat -0px -0px;
    background: url(${SpriteSVG}) no-repeat -0px -0px, none;
  `,
  CHAT: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 110px;
    height: 96px;
    background: url(${SpritePNG}) no-repeat -0px -44px;
    background: url(${SpriteSVG}) no-repeat -0px -44px, none;
  `,
  TROPHY: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 66px;
    height: 100px;
    background: url(${SpritePNG}) no-repeat -112px -44px;
    background: url(${SpriteSVG}) no-repeat -112px -44px, none;
  `,
  CALENDAR: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 94px;
    height: 106px;
    background: url(${SpritePNG}) no-repeat -0px -262px;
    background: url(${SpriteSVG}) no-repeat -0px -262px, none;
  `,
  PHONE: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 100px;
    height: 84px;
    background: url(${SpritePNG}) no-repeat -94px -262px;
    background: url(${SpriteSVG}) no-repeat -94px -262px, none;
  `,
  GENERALDEBT: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 114px;
    height: 68px;
    background: url(${SpritePNG}) no-repeat -180px -44px;
    background: url(${SpriteSVG}) no-repeat -180px -44px, none;
  `,
  CREDITCARD: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 124px;
    height: 86px;
    background: url(${SpritePNG}) no-repeat -0px -146px;
    background: url(${SpriteSVG}) no-repeat -0px -146px, none;
  `,
  STUDENTLOAN: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 158px;
    height: 104px;
    background: url(${SpritePNG}) no-repeat -126px -146px;
    background: url(${SpriteSVG}) no-repeat -126px -146px, none;
  `,
  MORTGAGE: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 110px;
    height: 116px;
    background: url(${SpritePNG}) no-repeat -286px -146px;
    background: url(${SpriteSVG}) no-repeat -286px -146px, none;
  `
};

export const CLOUDIMAGES = [
  css`
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: inline-block;
    // width: 100%;
    // height: 100%;
    // padding-bottom: 54.193548387096774%;
    background: url(${CloudAPNG}) no-repeat center center;
    background: url(${CloudASVG}) no-repeat center center, none;
    background-size: 100% 100%;
  `,
  css`
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: inline-block;
    // width: 100%;
    // height: 100%;
    // padding-bottom: 64.466292134831461%;
    background: url(${CloudBPNG}) no-repeat center center;
    background: url(${CloudBSVG}) no-repeat center center, none;
    background-size: 100% 100%;
  `,
  css`
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: inline-block;
    // width: 100%;
    // height: 100%;
    // padding-bottom: 43.385490753911807%;
    background: url(${CloudCPNG}) no-repeat center center;
    background: url(${CloudCSVG}) no-repeat center center, none;
    background-size: 100% 100%;
  `,
  css`
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: inline-block;
    // width: 100%;
    // height: 100%;
    // padding-bottom: 43.385490753911807%;
    background: url(${CloudC2PNG}) no-repeat center center;
    background: url(${CloudC2SVG}) no-repeat center center, none;
    background-size: 100% 100%;
    // transform: scaleX(-1);
    // filter: FlipH;
  `,
  css`
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: inline-block;
    // width: 100%;
    // height: 100%;
    // padding-bottom: 83.774834437086093%;
    background: url(${CloudDPNG}) no-repeat center center;
    background: url(${CloudDSVG}) no-repeat center center, none;
    background-size: 100% 100%;
  `,
  css`
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: inline-block;
    // width: 100%;
    // height: 100%;
    // padding-bottom: 83.774834437086093%;
    background: url(${CloudD2PNG}) no-repeat center center;
    background: url(${CloudD2SVG}) no-repeat center center, none;
    background-size: 100% 100%;
    // transform: scaleX(-1);
    // filter: FlipH;
  `,
  css`
    // position: absolute;
    // top: 0;
    // left: 0;
    // display: inline-block;
    // width: 100%;
    // height: 100%;
    // padding-bottom: 83.774834437086093%;
    background: url(${CloudD3PNG}) no-repeat center center;
    background: url(${CloudD3SVG}) no-repeat center center, none;
    background-size: 100% 100%;
    // transform: scaleY(-1);
    // filter: FlipV;
  `
];

export const MAX_WIDTH = "none";

export const DEVICE = {
  MAX_WIDTH: MAX_WIDTH,
  MONITOR: "1440px",
  LAPTOP: "1280px",
  TABLET_LANDSCAPE: "992px",
  TABLET_PORTRAIT: "768px",
  PHONE_LANDSCAPE: "480px"
};

export const SPACING = {
  SMALL: "8px",
  MEDIUM: "16px",
  LARGE: "24px",
  XLARGE: "48px",
  XXLARGE: "64px"
};

export const COLOR = {
  BACKGROUND: "#FFF",
  PRIMARY: "#000",
  SECONDARY: "#4d4d4d",
  TERTIARY: "#EDEDED",
  ACCENT_1: "#90433b",
  ACCENT_2: "#8AA75D"
};

export const FONT = {
  SIZE: {
    SMALL: "16px",
    MEDIUM: "24px",
    LARGE: "32px",
    XLARGE: "40px",
    XXLARGE: "48px",
    XXXLARGE: "56px",
    XXXXLARGE: "80px",
    MASSIVE: "140px",
    GIGANTIC: "2.2em"
  },
  FAMILY: {
    PRIMARY:
      "'FrutigerLTPro-Light', Courier, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif;",
    ACCENT:
      "'FrutigerLTPro-LightCn', Courier, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif;"
  }
};

export const ROTATE = {
  MESSAGE: css`
    display: inline-block;
    width: 100%;
    height: calc(100% - 42px - 24px);

    @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
      display: none;
    }
  `,
  IMAGE: css`
    display: inline-block;
    width: 100%;
    height: 60%;
    background: url(${MobileAnimation}) no-repeat -0px -0px;
    background-size: contain;
  `
};

export const KEYFRAMES = {
  BOB: keyframes`
            0% {
                transform: translateY(0)
            }
            100% {
                transform: translateY(-${SPACING.SMALL})
            }
        `,
  BOB_OFFSET: keyframes`
            0% {
                transform: translateY(0)
            }
            100% {
                transform: translateY(${SPACING.SMALL})
            }
        `,
  BOB_SHADOW_OFFSET: keyframes`
            0% {
                transform: translateY(0) scale(1);
            }
            100% {
                transform: translateY(${SPACING.SMALL}) scale(1.25);
            }
        `,
  GO: keyframes`
            0% {
                transform: translateX(0)
            }
            100% {
                transform: translateX(10%)
            }
        `,
  FADEIN: keyframes`
            0% { opacity: 0 }
            100% { opacity: 1}
        `,
  SLIDEIN: keyframes`
            0% { transform: translateX(200%); }
            100% { transform: translateX(0%); }
        `,
  FLIGHT: keyframes`
            0% {
                transform: translateY(2vw)
            }
            32% {
                transform: translateY(-2vw) translateX(-1vw);
            }
            66% {
                transform: translateY(-4vw) translateX(0);
            }
            100% {
                transform: translateY(-2vw) rotate(0);
            }
        `,
  FLIGHT_MAX: keyframes`
            0% {
                transform: translateY(-100px)
            }
            32% {
                transform: translateY(-150px) rotate(1deg);
            }
            66% {
                transform: translateY(-100px) rotate(-1deg);
            }
            100% {
                transform: translateY(-150px) rotate(0deg);
            }
        `,
  FLOAT_DOWN: keyframes`
            0% {
                opacity: 0;
                top: -10vw;
            }
            100% {
                opacity: 1;
                top: -5vw;
            }
        `,
  FLOAT_DOWN_FULL: keyframes`
            0% {
                opacity: 1;
                top: -5vw;
            }
            100% {
                opacity: 0;
                top: 70%;
            }
        `,
  JUMP: keyframes`
            0% {
                left: 24px;
                bottom: -60px;
            }
            50%{
                left: 10vw;
                bottom: 20px;
            }
            100% {
                left: 18vw;
                bottom: 6px;
            }
        `,
  PULSE: keyframes`
            0% {
                transform: scale(0.8)
            }

            100% {
                transform: scale(1)
            }
        `,
  TETHER_BOB: keyframes`
            0% {
                transform: rotate(-26deg)
            }
            100% {
                transform: rotate(-28deg)
            }
        `,
  TETHER_FALL: keyframes`
            0% {
                transform: rotate(-28deg) ;
                opacity: 1
                height: 14vw;
            }
            100% {
                transform: rotate(-50deg);
                opacity: 0
                height: 10vw;
            }
        `,
  CHEVRON: keyframes`
            0% {
                transform: translate(75%, 0) scale(0.75);
            }
            100% {
                transform: translate(175%, 0) scale(0.75);
            }
        `,
  CHEVRON_PHONE_LANDSCAPE: keyframes`
            0% {
                transform: translate(75%, 0) scale(0.75);
            }
            100% {
                transform: translate(175%, 0) scale(0.75);
            }
        `,
  CHEVRON_TABLET_PORTRAIT: keyframes`
            0% {
                transform: translate(85%, 0) scale(0.85);
            }
            100% {
                transform: translate(185%, 0) scale(0.85);
            }
        `,
  CHEVRON_TABLET_LANDSCAPE: keyframes`
            0% {
                transform: translate(95%, 0) scale(0.95);
            }
            100% {
                transform: translate(195%, 0) scale(0.95);
            }
        `,
  CHEVRON_LAPTOP: keyframes`
            0% {
                transform: translate(105%, 0) scale(1.05);
            }
            100% {
                transform: translate(205%, 0) scale(1.05);
            }
        `
};

export const FONT_STYLE = {
  DISPLAY_HEADLINE: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 2.4em;
    line-height: 1.2em;
    color: ${COLOR.PRIMARY};
    font-weight: normal;
    margin: 0;
  `,
  DISPLAY_HEADLINE_EMPHASIS: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 2em;
    line-height: 100%;
    color: ${COLOR.ACCENT_1};
    display: block;
    margin: 0;
  `,

  DISPLAY_BODY: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1.2em;
    line-height: 1.4em;
    color: ${COLOR.SECONDARY};
  `,

  QUIZ_HEADLINE: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1.8em;
    line-height: 1.25em;
    color: ${COLOR.PRIMARY};
    font-weight: normal;
    padding-bottom: 0.25em;
  `,
  QUIZ_BODY: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1em;
    line-height: 1.4em;
    color: ${COLOR.SECONDARY};
  `,

  HEADLINE: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1.6em;
    line-height: 1em;
    color: ${COLOR.PRIMARY};
    font-weight: normal;
  `,

  BODY: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 0.85em;
    line-height: 1.25em;
    color: ${COLOR.SECONDARY};
  `,

  DETAIL: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 0.7em;
    line-height: 1.4em;
    color: ${COLOR.SECONDARY};
  `,

  BUTTON: css`
    font-family: ${FONT.FAMILY.ACCENT};
    font-size: 1.2em;
    line-height: 1.2em;
    color: ${COLOR.PRIMARY};
    text-decoration: none;
  `
};

export const PATTERN = {
  CHEVRON: css`
            ${SPRITE.CHEVRON}

            @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}){
                transform: translate(75%, 0) scale(0.75);
                bottom: 4px;
            }

            @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}){
                transform: translate(75%, 0) scale(0.85);
                bottom: 6px;
            }

            @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}){
                transform: translate(75%, 0) scale(0.95);
                bottom: 7px;
            }

            @media screen and (min-width: ${DEVICE.LAPTOP}){
                transform: translate(75%, 0) scale(1.05);
                bottom: 11px;
            }
        `,
  FLEXCONTAINER: css`
            height: 100%;
            width: 100%;
            display: block;
            overflow-y: auto;
            overflow-x: hidden;
            padding: ${SPACING.LARGE};
            display: flex;
            flex-wrap: wrap;
            flex-grow: 10;
            position: relative;
            // flex-direction: column;

            // @media screen and (min-width: ${
              DEVICE.LAPTOP
            }) and (min-height: ${DEVICE.LAPTOP}){
            //     display: flex;
            //     flex-direction: column;
            // }

            // @media screen and (min-width: ${
              DEVICE.MAX_WIDTH
            }) and (min-height: ${DEVICE.LAPTOP}){

            // }
        `,
  FADEIN: css`
    animation: ${KEYFRAMES.FADEIN} 1s ease-out 1;
  `,
  SLIDEIN: css`
    animation: ${KEYFRAMES.SLIDEIN} 1s ease-out 1;
  `,
  PULSE: css`
    animation: ${KEYFRAMES.PULSE} 1s ease infinite alternate;
  `
};
