import React from "react";
import styled, { css } from "styled-components";
import {
  COLOR,
  DEVICE,
  CHARACTERS,
  CHARACTERSPNG,
  SPACING,
  KEYFRAMES,
  BALLOONS,
  BALLOONSPNG
} from "../constants";
import SelectDebts from "./SelectDebts";
import ProgressBags from "./ProgressBags";
import Clouds from "./Clouds";
import HelperText from "./HelperText";

const InteractiveContainer = styled.div`
  width: 50%;
  // flex-basis: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  transform-origin: center top;
  overflow: visible;

  ${props =>
    props.current !== "intro" &&
    css`
      justify-content: flex-start;
    `};
`;

const Balloon = styled.div`
    width: 100%;
    height: 68vw;
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: ${KEYFRAMES.BOB} 2s linear infinite alternate;
    z-index: 20;
    transform-origin: top center;

    ${props =>
      props.current !== "intro" &&
      css`
        animation: ${KEYFRAMES.FLIGHT} 6s linear infinite alternate;
      `}

    @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
        height: 900px;
    }
`;

const BalloonImage = styled.div`
    width: 100%;
    height: 100%;
    background: url(${BALLOONSPNG[0]}) no-repeat center bottom;
    background: url(${BALLOONS[0]}) no-repeat center bottom, none;
    background-size: 100% auto;
    position: relative;
    z-index: 50;

   @media all and (-ms-high-contrast:none) {
    background: url(${BALLOONSPNG[0]}) no-repeat center bottom; /* IE10 */
    background-size: auto 100%;
    
    *::-ms-backdrop { 
      background: url(${BALLOONSPNG[0]}) no-repeat center bottom;
      background-size: auto 100%;
    } /* IE11 */
   }
`;

const BalloonShadow = styled.div`
    margin: 30px 0 0 5px;
    width: 100%;
    height: 30px;
    background: url(${BALLOONSPNG[1]}) no-repeat center bottom;
    background: url(${BALLOONS[1]}) no-repeat center bottom, none;
    background-size: auto 100%;
    position: relative;
    z-index: 50;

    ${props =>
      props.current !== "intro" &&
      css`
        display: none;
      `}

    ${props =>
      props.current === "intro" &&
      css`
        animation: ${KEYFRAMES.BOB_SHADOW_OFFSET} 2s linear infinite alternate;
      `}
`;

const Tether = styled.div`
  width: 2px;
  height: 14vw;
  right: 10vw;
  background: ${COLOR.PRIMARY};
  position: absolute;
  bottom: -60px;
  transform: rotate(-25deg);
  transform-origin: 100% 100%;
  z-index: 10;
  animation: ${KEYFRAMES.TETHER_BOB} 2s linear infinite alternate;

  // @media screen and (min-width: ${DEVICE.MAX_WIDTH}) {
  //   height: 205px;
  //   right: 80px;
  // }

  ${props =>
    props.current !== "intro" &&
    css`
      animation: ${KEYFRAMES.TETHER_FALL} 0.1s ease-in 1;
      opacity: 0;
    `};
`;

const Spike = styled.div`
  width: 50px;
  height: 22px;
  right: 8.5vw;
  background: url(${BALLOONSPNG[2]}) no-repeat center bottom;
  background: url(${BALLOONS[2]}) no-repeat center bottom, none;
  background-size: auto 100%;
  position: absolute;
  bottom: -72px;
  z-index: 20;

  ${props =>
    props.current !== "intro" &&
    css`
      display: none;
    `};
`;

const Character = styled.div`
    width: 8vw;
    height: 14vw;
    position: absolute;
    bottom: 6px;
    left: ${SPACING.LARGE};
    z-index: 30;
    // animation: ${KEYFRAMES.BOB_OFFSET} 2s linear infinite alternate;

    ${props =>
      props.character === 1 &&
      css`
        background: url(${CHARACTERSPNG[0]}) no-repeat center center;
        background: url(${CHARACTERS[0]}) no-repeat center center, none;
        background-size: auto 100%;
      `}

    ${props =>
      props.character === 2 &&
      css`
        background: url(${CHARACTERSPNG[1]}) no-repeat center center;
        background: url(${CHARACTERS[1]}) no-repeat center center, none;
        background-size: auto 100%;
      `}

    ${props =>
      props.character === 3 &&
      css`
        background: url(${CHARACTERSPNG[2]}) no-repeat center center;
        background: url(${CHARACTERS[2]}) no-repeat center center, none;
        background-size: auto 100%;
      `}

    ${props =>
      props.character === 4 &&
      css`
      background: url(${CHARACTERSPNG[3]}) no-repeat center center;
        background: url(${CHARACTERS[3]}) no-repeat center center, none;
        background-size: auto 100%;
      `}

    ${props =>
      props.current === "intro" &&
      css`
        bottom: -60px;
        animation: ${KEYFRAMES.BOB_OFFSET} 2s linear infinite alternate;
      `}

    ${props =>
      props.current !== "intro" &&
      css`
        width: 10vw;
        height: 14vw;
        left: 18vw;
        bottom: 6px;
        z-index: 1;
        animation: ${KEYFRAMES.JUMP} 0.5s ease;
      `}

`;

class Interactive extends React.Component {
  render() {
    return (
      <InteractiveContainer
        current={this.props.current}
        onClick={() => {
          this.props.setProgress(this.props.current);
        }}
      >
        {this.props.current === "intro" && (
          <SelectDebts
            checkDebts={this.props.checkDebts}
            setDebts={this.props.setDebts}
            updateClickstream={this.props.updateClickstream}
          />
        )}
        {this.props.current !== "intro" && (
          <Clouds
            current={this.props.current}
            generalDebt={this.props.generalDebt}
            creditCard={this.props.creditCard}
            studentLoan={this.props.studentLoan}
            mortgage={this.props.mortgage}
          />
        )}
        <Balloon current={this.props.current}>
          <ProgressBags
            current={this.props.current}
            generalDebt={this.props.generalDebt}
            creditCard={this.props.creditCard}
            studentLoan={this.props.studentLoan}
            mortgage={this.props.mortgage}
            checkDebts={this.props.checkDebts}
            debts={this.props.debts}
            updateClickstream={this.props.updateClickstream}
          />
          <BalloonImage current={this.props.current} />
          <BalloonShadow current={this.props.current} />
          <Character
            character={this.props.character}
            current={this.props.current}
          />
        </Balloon>
        <Tether current={this.props.current} />
        <Spike current={this.props.current} />
        {this.props.current !== "intro" && (
          <HelperText
            current={this.props.current}
            generalDebt={this.props.generalDebt}
            creditCard={this.props.creditCard}
            studentLoan={this.props.studentLoan}
            mortgage={this.props.mortgage}
            setOffsiteProgress={this.props.setOffsiteProgress}
            updateClickstream={this.props.updateClickstream}
          />
        )}
      </InteractiveContainer>
    );
  }
}

export default Interactive;
