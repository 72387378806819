import React from "react";
import styled from "styled-components";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import firebase from "../firebase.js";
import moment from "moment";
import { MAX_WIDTH, SPACING, COLOR, DEVICE } from "../constants";
import Landing from "./Landing";
import Characters from "./Characters";
import Quiz from "./Quiz";
import Ending from "./Ending";
import Contact from "./Contact";
import Legal from "./Legal";
import data from "../content.json";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  border: ${SPACING.LARGE} solid ${COLOR.TERTIARY};
  background: white;
`;

const WidthConstraint = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${MAX_WIDTH};

  @media screen and (min-width: ${DEVICE.MAX_WIDTH}) {
    margin: 0 auto;
  }
`;

class App extends React.Component {
  state = {
    marketingId: null,
    canAdvance: false,
    quizComplete: false,
    current: "intro",
    complete: false,
    debts: ["generalDebt"],
    indexOfDebt: -1,
    character: 1,
    intro: 2,
    generalDebt: 0,
    creditCard: 0,
    studentLoan: 0,
    mortgage: 0,
    offsite: [],
    clickUrl: null,
    lastClick: null,
    clickstream: []
  };

  advance = () => {
    if (this.state.indexOfDebt <= this.state.debts.length - 1) {
      this.updateLastClick("Next");
      this.updateClickstream(
        data.shared.debts[this.state.indexOfDebt + 1],
        "Next"
      );
      this.setState(prevState => ({
        indexOfDebt: prevState.indexOfDebt + 1,
        current: this.state.debts[prevState.indexOfDebt + 1],
        canAdvance: false
      }));
    } else if (this.state[this.state.current] >= 2) {
      this.setState({
        quizComplete: true
      });
    }
  };

  checkProgress = () => {
    if (
      this.state.indexOfDebt >= this.state.debts.length - 1 &&
      this.state[this.state.current] >= 2
    ) {
      this.setState({
        quizComplete: true
      });
    } else {
      this.state[this.state.current] >= 1 &&
        this.setState({ canAdvance: true });
    }
  };

  setCharacter = selection => {
    this.setState({
      character: selection
    });
  };

  checkDebts = debtType => {
    const i = this.state.debts.indexOf(debtType);
    if (i > -1) {
      return true;
    } else {
      return false;
    }
  };

  setDebts = debtType => {
    this.setState(({ debts }) => ({
      debts: [...debts, debtType]
    }));
  };

  setProgress = debtType => {
    this.state[debtType] <= 1 &&
      this.setState(prevState => ({
        [debtType]: prevState[debtType] + 1
      }));
    this.checkProgress();
  };

  // Sets the character prop in state to the name of the selected character

  setCharacter = character => {
    this.setState({
      character: character
    });
  };

  // Records any offsite links visited

  setOffsiteProgress = destination => {
    this.setState(({ offsite }) => ({
      offsite: [...offsite, destination]
    }));
  };

  // Called at initial app load
  // If the user has a marketing ID appended to their URL, it gets logged in state
  // Otherwise, if no ID is found, it prints an error message to state

  setMarketingId = id => {
    if (id) {
      this.setState({
        marketingId: id
      });
    } else {
      this.setState({
        marketingId: "No ID found"
      });
    }
  };

  // Creates a new database key for this quiz session
  // All progress is written to this key as the user completes the quiz

  setDatabaseRef = () => {
    const newRef = firebase
      .database()
      .ref()
      .push();
    this.setState({
      databaseKey: newRef.key
    });
  };

  updateClickstream = (name, action, cta = false) => {
    let lastClick = action ? action : this.state.lastClick;
    const timeStr = moment().format("YYYY-MM-DDTHH:mm:ss");

    let clickKey = "choice";
    if ((name && name.startsWith("http")) || cta === true) {
      clickKey = "CTA";
    }
    let click = {
      [clickKey]: lastClick || "Article page: Play Now",
      time: timeStr
    };
    let destination;
    if (name) {
      destination = {
        page: name,
        time: timeStr
      };
    }

    this.setState(({ clickstream }) => {
      let newClickstream;
      if (destination) {
        newClickstream = [...clickstream, click, destination];
      } else {
        newClickstream = [...clickstream, click];
      }
      return {
        clickstream: newClickstream
      };
    });
  };

  updateLastClick = name => {
    this.setState({
      lastClick: name,
      clickUrl: window.location.href
    });
  };

  // Writes the state object to firebase under a new key
  // Note that, even if a user retakes the quiz with the same marketing ID, it will generate a new entry in Firebase

  // IMPORTANT!!!!
  // REPLACE FIREBASE.JS BEFORE LAUNCH! The attached database is insecure and is meant for testing purposes ONLY!

  pushProgress(reference) {
    const progress = {
      marketingId: this.state.marketingId,
      character: this.state.character,
      clickstream: this.state.clickstream,
      otherProgress: {
        selectedDebts: this.state.debts,
        generalDebtProgress: this.state.generalDebt,
        creditCardProgress: this.state.creditCard,
        studentLoanProgress: this.state.studentLoan,
        mortgageProgress: this.state.mortgage,
        offsite: this.state.offsite
      }
    };

    firebase
      .database()
      .ref(reference)
      .update(progress);
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    this.setMarketingId(urlParams.get("mpid"));
    this.setDatabaseRef();
    document.title = "Ready for Takeoff";
  }

  componentDidUpdate() {
    this.pushProgress("/readyForTakeoff/" + this.state.databaseKey);
  }

  render() {
    return (
      <BrowserRouter>
        <AppContainer>
          <WidthConstraint>
            <Switch>
              <Route
                path="/"
                exact
                render={() => (
                  <Landing
                    updateClickstream={this.updateClickstream}
                    updateLastClick={this.updateLastClick}
                  />
                )}
              />

              <Route
                path="/characters"
                exact
                render={() => (
                  <Characters
                    setCharacter={this.setCharacter}
                    updateClickstream={this.updateClickstream}
                    updateLastClick={this.updateLastClick}
                  />
                )}
              />

              <Route
                path="/start"
                exact
                render={() => (
                  <Quiz
                    advance={this.advance}
                    checkProgress={this.checkProgress}
                    setCharacter={this.setCharacter}
                    canAdvance={this.state.canAdvance}
                    character={this.state.character}
                    current={this.state.current}
                    debts={this.state.debts}
                    checkDebts={this.checkDebts}
                    setDebts={this.setDebts}
                    generalDebt={this.state.generalDebt}
                    creditCard={this.state.creditCard}
                    studentLoan={this.state.studentLoan}
                    mortgage={this.state.mortgage}
                    setProgress={this.setProgress}
                    quizComplete={this.state.quizComplete}
                    setOffsiteProgress={this.setOffsiteProgress}
                    updateLastClick={this.updateLastClick}
                    updateClickstream={this.updateClickstream}
                  />
                )}
              />

              <Route
                path="/quiz"
                exact
                render={() => (
                  <Quiz
                    advance={this.advance}
                    checkProgress={this.checkProgress}
                    setCharacter={this.setCharacter}
                    canAdvance={this.state.canAdvance}
                    character={this.state.character}
                    current={this.state.current}
                    debts={this.state.debts}
                    checkDebts={this.checkDebts}
                    setDebts={this.setDebts}
                    generalDebt={this.state.generalDebt}
                    creditCard={this.state.creditCard}
                    studentLoan={this.state.studentLoan}
                    mortgage={this.state.mortgage}
                    setProgress={this.setProgress}
                    quizComplete={this.state.quizComplete}
                    setOffsiteProgress={this.setOffsiteProgress}
                    updateLastClick={this.updateLastClick}
                    updateClickstream={this.updateClickstream}
                  />
                )}
              />

              <Route
                path="/ending"
                exact
                render={() => (
                  <Ending
                    updateClickstream={this.updateClickstream}
                    updateLastClick={this.updateLastClick}
                  />
                )}
              />

              <Route
                path="/contact"
                exact
                render={() => (
                  <Contact
                    updateClickstream={this.updateClickstream}
                    updateLastClick={this.updateLastClick}
                  />
                )}
              />

              <Route
                path="/legal"
                exact
                render={() => (
                  <Legal
                    updateClickstream={this.updateClickstream}
                    updateLastClick={this.updateLastClick}
                  />
                )}
              />
            </Switch>
          </WidthConstraint>
        </AppContainer>
      </BrowserRouter>
    );
  }
}

export default App;
