import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  PATTERN,
  FONT_STYLE,
  SPRITE,
  DEVICE,
  KEYFRAMES,
  ROTATE
} from "../constants";
import Header from "./Header";
import data from "../content.json";

const EndingContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const ContentContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
const Headline = styled.h1`
  ${FONT_STYLE.DISPLAY_HEADLINE}
  margin: 0;

  // flex-grow: 1;
  // display: flex;
  // flex-direction: column;
`;

const Emphasis = styled.span`
  ${FONT_STYLE.DISPLAY_HEADLINE_EMPHASIS}
  margin-top: -0.25em;
  display: block;
`;

const Paragraph = styled.p`
  ${FONT_STYLE.DISPLAY_BODY}
  width: 100%;
  display: inline-block;
  margin: 0 auto 40px 0;
`;

const Options = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 1em auto 0;
  // align-items: center;
`;

const Option = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    width: 40%;
    max-width: 400px;
    display: flex;
    // flex-direction: column;
  }
`;

const OptionImage = styled.div`
  width: 100%;
  height: 10vw;
  margin: 0 auto 10px;

  ${props => (props.type === "trophy" ? SPRITE.TROPHY : SPRITE.CHAT)}
`;

const OptionText = styled.p`
  ${FONT_STYLE.BUTTON}
  margin: 0;
  position: relative;
  padding-right: 1.6em;

  &::after {
    ${PATTERN.CHEVRON}
  }
`;

const OptionExternalLink = styled.a`
  text-decoration: none;

  &:hover {
    p::after {
      animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;

      @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
          alternate;
      }

      @media screen and (min-width: ${DEVICE.LAPTOP}) {
        animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
      }
    }
  }
`;
const OptionInternalLink = styled(Link)`
  text-decoration: none;

  &:hover {
    p::after {
      animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;

      @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
          alternate;
      }

      @media screen and (min-width: ${DEVICE.LAPTOP}) {
        animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
      }
    }
  }
`;

class Ending extends React.Component {
  componentDidMount() {
    this.props.updateClickstream(data.ending.headline.join(' '));
  }

  render() {
    return (
      <EndingContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <ContentContainer>
          <Headline>
            {data.ending.headline[0]}
            <Emphasis>{data.ending.headline[1]}</Emphasis>
          </Headline>
          <Paragraph>{data.ending.body}</Paragraph>
        </ContentContainer>
        <Options>
          <Option>
            <OptionExternalLink
              href="https://onesourcedt.ubs.com/CEFSWebApp/fwwellness.do?/"
              target="_blank"
              onClick={() => {
                this.props.updateClickstream("https://onesourcedt.ubs.com/CEFSWebApp/fwwellness.do?/", data.ending.cta[0]);
              }}
            >
              <OptionImage type="trophy" />
              <OptionText>{data.ending.cta[0]}</OptionText>
            </OptionExternalLink>
          </Option>
          <Option>
            <OptionInternalLink
              to="/contact"
              onClick={() =>{
                this.props.updateLastClick(data.ending.cta[1]);
              }}
            >
              <OptionImage type="bubbles" />
              <OptionText>{data.ending.cta[1]}</OptionText>
            </OptionInternalLink>
          </Option>
        </Options>
      </EndingContainer>
    );
  }
}

export default Ending;
