import React from "react";
import styled from "styled-components";
import { FONT_STYLE, SPACING, COLOR, PATTERN, SPRITE } from "../constants";
import data from "../content.json";

const QuizTextContainer = styled.div`
    width: 50%;
    // flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-grow: 1;
    padding-right: ${SPACING.MEDIUM};
    ${PATTERN.FADEIN}
    margin-top: ${SPACING.LARGE}
    position: relative;
`;

const SectionImage = styled.div`
    width: 100%;
    height: 3vw;
    margin-left: ${SPACING.LARGE};
    margin-bottom: ${SPACING.LARGE};

    ${props => props.current === "generalDebt" && SPRITE.GENERALDEBT}
    ${props => props.current === "creditCard" && SPRITE.CREDITCARD}
    ${props => props.current === "studentLoan" && SPRITE.STUDENTLOAN}
    ${props => props.current === "mortgage" && SPRITE.MORTGAGE}
`;

const Headline = styled.h1`
  ${FONT_STYLE.QUIZ_HEADLINE}
  width: 100%;
`;

const Paragraph = styled.p`
  ${FONT_STYLE.QUIZ_BODY}
  flex-grow: 1;
  width: 100%;
`;

const HelpBubble = styled.div`
  ${FONT_STYLE.DETAIL};
  width: 300px;
  padding: 1em;
  border: 1px solid ${COLOR.SECONDARY};
  border-radius: ${SPACING.SMALL};
  margin: 0;
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: -20px;
  background: ${COLOR.BACKGROUND};
  z-index: 100;

  &:after,
  &:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: ${COLOR.BACKGROUND};
    border-width: 16px;
    margin-top: -16px;
  }

  &:before {
    border-color: rgba(119, 119, 119, 0);
    border-left-color: ${COLOR.SECONDARY};
    border-width: 18px;
    margin-top: -18px;
  }
`;

class QuizText extends React.Component {
  state = {
    isLoaded: false
  };

  loadContent = () => {
    this.props.current === "intro" &&
      this.setState({
        isLoaded: true,
        headline: data.intro.headline,
        paragraph: data.intro.body,
        help: data.intro.directions
      });

    this.props.current === "generalDebt" &&
      this.setState({
        isLoaded: true,
        headline: data.shared.debts[0],
        paragraph: data.generalDebt.body,
        help: false
      });

    this.props.current === "creditCard" &&
      this.setState({
        isLoaded: true,
        headline: data.shared.debts[1],
        paragraph: data.creditCard.body,
        help: false
      });

    this.props.current === "studentLoan" &&
      this.setState({
        isLoaded: true,
        headline: data.shared.debts[2],
        paragraph: data.studentLoan.body,
        help: false
      });

    this.props.current === "mortgage" &&
      this.setState({
        isLoaded: true,
        headline: data.shared.debts[3],
        paragraph: data.mortgage.body,
        help: false
      });
  };

  componentDidMount() {
    this.loadContent();
  }

  componentDidUpdate(prevProps) {
    if (this.props.current !== prevProps.current) {
      this.loadContent();
    }
  }

  render() {
    return (
      <QuizTextContainer>
        {this.state.isLoaded && this.props.current !== "intro" && (
          <SectionImage current={this.props.current} />
        )}
        {this.state.isLoaded && <Headline>{this.state.headline}</Headline>}
        {this.state.isLoaded && <Paragraph>{this.state.paragraph}</Paragraph>}
        {this.state.isLoaded && this.state.help && (
          <HelpBubble>{this.state.help}</HelpBubble>
        )}
      </QuizTextContainer>
    );
  }
}

export default QuizText;
