import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import data from "../content.json";

import Header from "./Header";
import {
  FONT_STYLE,
  SPACING,
  PATTERN,
  DEVICE,
  CHARACTERS,
  CHARACTERSPNG,
  ROTATE
} from "../constants";

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const CharactersContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
  align-content: flex-start;
`;

const Headline = styled.h1`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_HEADLINE}
    ${PATTERN.FADEIN}
    width: 100%;
    margin: 0;
    line-height: 1.25em;
  }
`;

const Paragraph = styled.p`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_BODY}
    margin-bottom: ${SPACING.LARGE};
    ${PATTERN.FADEIN}
    width: 100%;
    margin: 0 0 60px;
  }
`;

const OptionContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: flex;
    flex-grow: 1;
    align-items: center;
    ${PATTERN.FADEIN}
    width: 100%;
  }
`;

const Option = styled(Link)`
    display: inline-block;
    flex-basis: 25%;
    height: auto;
    transition: transform 0.3s ease;
    height: 45vh;

    &:hover {
        transform: scale(1.1);
    }
    
    ${props =>
      props.character === 1 &&
      css`
        background: url(${CHARACTERSPNG[0]}) no-repeat center center;
        background: url(${CHARACTERS[0]}) no-repeat center center, none;
        background-size: auto 100%;
      `}

    ${props =>
      props.character === 2 &&
      css`
        background: url(${CHARACTERSPNG[1]}) no-repeat center center;
        background: url(${CHARACTERS[1]}) no-repeat center center, none;
        background-size: auto 100%;
      `}

    ${props =>
      props.character === 3 &&
      css`
        background: url(${CHARACTERSPNG[2]}) no-repeat center center;
        background: url(${CHARACTERS[2]}) no-repeat center center. none;
        background-size: auto 100%;
      `}

    ${props =>
      props.character === 4 &&
      css`
        background: url(${CHARACTERSPNG[3]}) no-repeat center center;
        background: url(${CHARACTERS[3]}) no-repeat center center, none;
        background-size: auto 100%;
      `}

    // @media screen and (min-width: ${DEVICE.MAX_WIDTH}){
    //     height: 45vh;
    // }
`;

class Characters extends React.Component {
  componentDidMount() {
    this.props.updateClickstream(data.characters.headline.join(' '));
  }
  render() {
    return (
      <CharactersContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <Headline>{data.characters.headline}</Headline>
        <Paragraph>{data.characters.body[0]}</Paragraph>
        <OptionContainer>
          <Option
            to="/start"
            character={1}
            onClick={() => {
              this.props.setCharacter(1);
              this.props.updateLastClick('Character 1');
            }}
          />
          <Option
            to="/start"
            character={2}
            onClick={() => {
              this.props.setCharacter(2);
              this.props.updateLastClick('Character 2');
            }}
          />
          <Option
            to="/start"
            character={3}
            onClick={() => {
              this.props.setCharacter(3);
              this.props.updateLastClick('Character 3');
            }}
          />
          <Option
            to="/start"
            character={4}
            onClick={() => {
              this.props.setCharacter(4);
              this.props.updateLastClick('Character 4');
            }}
          />
        </OptionContainer>
      </CharactersContainer>
    );
  }
}

export default Characters;
