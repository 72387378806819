import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FONT_STYLE,
  SPACING,
  KEYFRAMES,
  PATTERN,
  DEVICE,
  ROTATE
} from "../constants";
import data from "../content.json";
import Header from "./Header";
import QuizText from "./QuizText";
import Interactive from "./Interactive";

const QuizContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
`;

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const QuizContent = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: flex;
    flex-grow: 10;
    height: 70vh;
    ${PATTERN.SLIDEIN}
  }
`;

const ButtonWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: ${SPACING.LARGE};
    height: auto;
    flex-grow: 1;
    z-index: 210;
    align-self: flex-end;
  }
`;

const AdvanceButton = styled(Link)`
  ${FONT_STYLE.BUTTON}
  cursor: pointer;
  position: relative;
  padding-right: 1.6em;
  ${PATTERN.SLIDEIN}

  &::after {
    ${PATTERN.CHEVRON}
  }

  &:hover {
    &::after {
      animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;

      @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
          alternate;
      }

      @media screen and (min-width: ${DEVICE.LAPTOP}) {
        animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
      }
    }
  }
`;

class Quiz extends React.Component {
  componentDidMount() {
    this.props.checkProgress();
    this.props.updateClickstream(data.intro.headline.join(' '));
  }

  componentDidUpdate(prevState) {
    if (this.props.canAdvance !== prevState.canAdvance) {
      this.props.checkProgress();
    }
  }

  render() {
    return (
      <QuizContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <QuizContent>
          <QuizText
            current={this.props.current}
            generalDebt={this.props.generalDebt}
            creditCard={this.props.creditCard}
            studentLoan={this.props.studentLoan}
            mortgage={this.props.mortgage}
            debts={this.props.debts}
          />
          <Interactive
            character={this.props.character}
            current={this.props.current}
            checkDebts={this.props.checkDebts}
            setDebts={this.props.setDebts}
            setProgress={this.props.setProgress}
            generalDebt={this.props.generalDebt}
            creditCard={this.props.creditCard}
            studentLoan={this.props.studentLoan}
            mortgage={this.props.mortgage}
            setOffsiteProgress={this.props.setOffsiteProgress}
            debts={this.props.debts}
            updateClickstream={this.props.updateClickstream}
          />
        </QuizContent>
        <ButtonWrapper>
          {this.props.canAdvance && !this.props.quizComplete && (
            <AdvanceButton to="/quiz" onClick={this.props.advance}>
              {this.props.current === "intro"
                ? data.intro.cta
                : data.shared.cta[0]}
            </AdvanceButton>
          )}
          {this.props.quizComplete && (
            <AdvanceButton
              to="/Ending"
              onClick={() => {
                this.props.updateLastClick('Next');
              }}
            >
              Next
            </AdvanceButton>
          )}
        </ButtonWrapper>
      </QuizContainer>
    );
  }
}

export default Quiz;
