import React from "react"
import styled, { css } from "styled-components"
import { PATTERN, FONT_STYLE, SPACING, COLOR, DEVICE, BAGS, BAGSPNG } from "../constants.js"
import data from "../content.json"

const SelectDebtsContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1vw;
    right: 0;
    width: 14vw;
    z-index: 50;
    min-width: 100px;
    max-width: 150px;
`

const DebtContainer = styled.button`
    ${FONT_STYLE.BODY}
    font-size: 1em;
    outline: none;
    background: transparent;
    border: none;
    display: flex;
    cursor: pointer;
    margin-bottom: ${SPACING.SMALL}

    &:hover {
        span {
            ${PATTERN.PULSE}
        }
    }
`

const Debt = styled.div`
    ${FONT_STYLE.DETAIL}
    line-height: 1.2em;
    color: ${COLOR.PRIMARY};
    text-align: left;
    margin-left: 5px;
    opacity: .5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ${SPACING.LARGE};
    width: 40px;
    overflow: visible;

    @media screen and (min-width: ${DEVICE.LAPTOP}){
        height: ${SPACING.XLARGE}
    }
`

const Bag = styled.div`
    width: ${SPACING.LARGE};
    height: ${SPACING.LARGE};
    display: block;
    background-size: 100%;
    z-index: 60;
    min-width: ${SPACING.LARGE};
    min-height: ${SPACING.LARGE};

    @media screen and (min-width: ${DEVICE.LAPTOP}){
        width: ${SPACING.XLARGE};
        height: ${SPACING.XLARGE};
    }

    ${props=>props.type==="creditCard" && css`
        background: url(${BAGSPNG[1]}) no-repeat center;
        background: url(${BAGS[1]}) no-repeat center, none;
        background-size: auto 100%;
    `}

    ${props=>props.type==="studentLoan" && css`
        background: url(${BAGSPNG[2]}) no-repeat center;
        background: url(${BAGS[2]}) no-repeat center, none;
        background-size: auto 100%;
    `}

    ${props=>props.type==="mortgage" && css`
        background: url(${BAGSPNG[3]}) no-repeat center;
        background: url(${BAGS[3]}) no-repeat center, none;
        background-size: auto 100%;
    `}
`

class SelectDebts extends React.Component {
    render(){
        return(
            <SelectDebtsContainer>
                {!this.props.checkDebts("creditCard") &&
                  <DebtContainer
                    onClick={() => {
                      this.props.setDebts("creditCard");
                      this.props.updateClickstream(null, `Selected ${data.shared.debts[1]}`)
                    }}
                  >
                    <Bag type="creditCard"/><Debt>{data.shared.debts[1]}</Debt>
                  </DebtContainer>
                }
                {!this.props.checkDebts("studentLoan") &&
                  <DebtContainer
                    onClick={() => {
                      this.props.setDebts("studentLoan");
                      this.props.updateClickstream(null, `Selected ${data.shared.debts[2]}`)
                    }}
                  >
                    <Bag type="studentLoan"/><Debt>{data.shared.debts[2]}</Debt>
                  </DebtContainer>
                }
                {!this.props.checkDebts("mortgage") &&
                  <DebtContainer
                    onClick={() => {
                      this.props.setDebts("mortgage")
                      this.props.updateClickstream(null, `Selected ${data.shared.debts[3]}`)
                    }}
                  >
                    <Bag type="mortgage"/><Debt>{data.shared.debts[3]}</Debt>
                  </DebtContainer>
                }
            </SelectDebtsContainer>
        )
    }
}

export default SelectDebts;