import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  PATTERN,
  FONT_STYLE,
  SPACING,
  DEVICE,
  KEYFRAMES,
  ROTATE
} from "../constants";
import Header from "./Header";
import data from "../content.json";

const LegalContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const ContentContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const LegalCopy = styled.div`
  display: inline-block;
  width: 100%;
  overflow-y: scroll;
`;

const Paragraph = styled.p`
  ${FONT_STYLE.QUIZ_BODY}

  width: 100%;
  display: inline-block;
  font-size: 1em;
  margin: 0 0 0.75em;
  color: black;
  line-height: 1.4em;
  padding: 0;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    font-size: 0.6em;
  }

  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    font-size: 0.7em;
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    font-size: 0.8em;
  }

  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    font-size: 0.9em;
  }
`;

// const Spacer = styled.div`
//     flex-grow: 1;
// `

const ButtonWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    width: 100%;
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: ${SPACING.LARGE};
    height: auto;
    position: relative;
    z-index: 210;
  }
`;

const LegalLink = styled(Link)`
  ${FONT_STYLE.BUTTON}
  cursor: pointer;
  position: relative;
  padding-right: 1.6em;

  &::after {
    ${PATTERN.CHEVRON}
  }

  &:hover {
    &::after {
      animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;

      @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
          alternate;
      }

      @media screen and (min-width: ${DEVICE.LAPTOP}) {
        animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
      }
    }
  }
`;

class Legal extends React.Component {
  state = {
    isLoaded: false
  };

  loadContent = () => {
    this.setState({
      isLoaded: true,
      content: data.legal.body
    });
  };

  componentDidMount() {
    this.loadContent();
    this.props.updateClickstream("Legal");
  }

  render() {
    return (
      <LegalContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <ContentContainer>
          {this.state.isLoaded && (
            <LegalCopy>
              <Paragraph>{this.state.content[0]}</Paragraph>
              <Paragraph>{this.state.content[1]}</Paragraph>
              <Paragraph>{this.state.content[2]}</Paragraph>
              <Paragraph>{this.state.content[3]}</Paragraph>
            </LegalCopy>
          )}
        </ContentContainer>
        <ButtonWrapper>
          <LegalLink
            to="/contact"
            onClick={() => {
              this.props.updateLastClick(data.legal.btn.join(" "));
            }}
          >
            {data.legal.btn}
          </LegalLink>
        </ButtonWrapper>
      </LegalContainer>
    );
  }
}

export default Legal;
