import React from "react"
import styled, { css } from "styled-components"
import { SPACING, FONT_STYLE, KEYFRAMES, CLOUDIMAGES } from "../constants";
import data from "../content.json"

const CloudContainer = styled.div`
    overflow: hidden;
    height: 100%;
    pointer-events: none;
`

const Cloud = styled.div`
    position: absolute;
    z-index: 500;
    top: -5vw;
    opacity: 0;
    // max-width: 60%;
    pointer-events: none;

    ${props=>!props.reverse && css`
        left: 0%;
    `}
    
    ${props=>props.reverse && css`
        right: 0%;
    `}

    ${props=>props.progress === props.cloudKey && css`
        opacity: 1;
        animation: ${KEYFRAMES.FLOAT_DOWN} 2s ease 1;
    `}

    ${props=>props.progress > props.cloudKey && css`
        opacity: 0;
        animation: ${KEYFRAMES.FLOAT_DOWN_FULL} 2s ease 1;
    `}

    ${props=>props.current === "generalDebt" && props.cloudKey === 2 && css`
        // max-width: 60%;
    `}

    ${props=>props.current === "mortgage" && props.cloudKey === 2 && css`
        // max-width: 70%;
    `}
`

const CloudText = styled.div`
    ${FONT_STYLE.DETAIL}  
    position: relative;
    width: 100%;
    height: auto;

    ${props=>props.current === "generalDebt" && css`
        ${props=>props.cloudKey === 0 && css`
            padding: 78px 60px 86px 76px;
            ${CLOUDIMAGES[2]}
            width: 494px;
        `}
        ${props=>props.cloudKey === 1 && css`
            padding: 62px 38px 56px 46px;
            ${CLOUDIMAGES[0]}
            width: 310px;
        `}
        ${props=>props.cloudKey === 2 && css`
            padding: 100px 60px 66px 65px;
            ${CLOUDIMAGES[1]}
            width: 398px;
        `}
    `}

    ${props=>props.current === "creditCard" && css`
        ${props=>props.cloudKey === 0 && css`
            padding: 80px 60px 85px 55px;
            ${CLOUDIMAGES[3]}
            width: 410px;
        `}
        ${props=>props.cloudKey === 1 && css`
            padding: 56px 54px 58px 68px;
            ${CLOUDIMAGES[0]}
            width: 330px;
        `}
        ${props=>props.cloudKey === 2 && css`
            padding: 80px 50px 50px 50px;
            ${CLOUDIMAGES[1]}
            width: 290px;
        `}
    `}

    ${props=>props.current === "studentLoan" && css`
        ${props=>props.cloudKey === 0 && css`
            padding: 84px 72px 90px 78px;
            ${CLOUDIMAGES[6]}
            width: 380px;
        `}
        ${props=>props.cloudKey === 1 && css`
            padding: 94px 66px 88px 72px;
            ${CLOUDIMAGES[4]}
            width: 396px;
        `}
        ${props=>props.cloudKey === 2 && css`
            padding: 115px 80px 115px 110px;
            ${CLOUDIMAGES[0]}
            width: 600px;
        `}
    `}

    ${props=>props.current === "mortgage" && css`
        ${props=>props.cloudKey === 0 && css`
            padding: 70px 50px 70px 50px;
            ${CLOUDIMAGES[0]}
            width: 370px;
        `}
        ${props=>props.cloudKey === 1 && css`
            padding: 88px 78px 88px 72px;
            ${CLOUDIMAGES[4]}
            width: 386px;
        `}
        ${props=>props.cloudKey === 2 && css`
            padding: 120px 70px 105px 90px;
            ${CLOUDIMAGES[5]}
            width: 430px;
        `}
    `}
`

const CloudFootnote = styled.div`
    position: fixed;
    bottom: ${SPACING.XLARGE};
    left: ${SPACING.XLARGE};
    ${FONT_STYLE.BODY}
`

// const CloudImage = styled.div`
//     position: relative;
//     width: 100%;
//     height: 100%;
//     z-index: 299;
//     ${props=>props.current === "generalDebt" && css`
//         ${props=>props.cloudKey === 0 && css`
//             ${CLOUDIMAGES[2]}
//         `}
//         ${props=>props.cloudKey === 1 && css`
//             ${CLOUDIMAGES[0]}
//         `}
//         ${props=>props.cloudKey === 2 && css`
//             ${CLOUDIMAGES[1]}
//         `}
//     `}

//     ${props=>props.current === "creditCard" && css`
//         ${props=>props.cloudKey === 0 && css`
//             ${CLOUDIMAGES[3]}
//         `}
//         ${props=>props.cloudKey === 1 && css`
//             ${CLOUDIMAGES[0]}
//         `}
//         ${props=>props.cloudKey === 2 && css`
//             ${CLOUDIMAGES[1]}
//         `}
//     `}

//     ${props=>props.current === "studentLoan" && css`
//         ${props=>props.cloudKey === 0 && css`
//             ${CLOUDIMAGES[5]}
//         `}
//         ${props=>props.cloudKey === 1 && css`
//             ${CLOUDIMAGES[4]}
//         `}
//         ${props=>props.cloudKey === 2 && css`
//             ${CLOUDIMAGES[0]}
//         `}
//     `}

//     ${props=>props.current === "mortgage" && css`
//         ${props=>props.cloudKey === 0 && css`
//             ${CLOUDIMAGES[4]}
//         `}
//         ${props=>props.cloudKey === 1 && css`
//             ${CLOUDIMAGES[0]}
//         `}
//         ${props=>props.cloudKey === 2 && css`
//             ${CLOUDIMAGES[5]}
//         `}
//     `}
// `

class Clouds extends React.Component {
    state = {
        isLoaded: false,
        content: null
    }

    loadContent = () => {
        this.setState({
            isLoaded: true,
            content: data[this.props.current].clouds,
            note: data[this.props.current].footnote
        })
    }

    componentDidMount(){
        this.loadContent()
    }

    componentDidUpdate(){
        if(data[this.props.current].clouds !== this.state.content) {
            this.loadContent()
        }
    }

    render(){
        return(
            <CloudContainer>
                <Cloud cloudKey={0} progress={this.props[this.props.current]} current={this.props.current}>
                    {this.state.isLoaded && <CloudText className="cloud-text" cloudKey={0} current={this.props.current}>{this.buildCloudContent(this.state.content[0])}</CloudText>}
                    {this.state.isLoaded && <CloudFootnote cloudKey={0} current={this.props.current}>{this.state.note[0]}</CloudFootnote>}
                </Cloud>
                <Cloud cloudKey={1} progress={this.props[this.props.current]} current={this.props.current} reverse>
                    {this.state.isLoaded && <CloudText className="cloud-text" cloudKey={1} current={this.props.current}>{this.buildCloudContent(this.state.content[1])}</CloudText>}
                    {this.state.isLoaded && <CloudFootnote cloudKey={1} current={this.props.current}>{this.state.note[1]}</CloudFootnote>}
                </Cloud>
                <Cloud cloudKey={2} progress={this.props[this.props.current]} current={this.props.current}>
                    {this.state.isLoaded && <CloudText className="cloud-text" cloudKey={2} current={this.props.current}>{this.buildCloudContent(this.state.content[2])}</CloudText>}
                    {this.state.isLoaded && <CloudFootnote cloudKey={2} current={this.props.current}>{this.state.note[2]}</CloudFootnote>}
                </Cloud>
            </CloudContainer>
        )
    }

    buildCloudContent(content) {
      let elements = [];
      let count = 0;
      if (content.p && content.p.length) {
        content.p.forEach(p => {
          elements.push(<p key={count++}>{p}</p>);
        });
      }
      if (content.li && content.li.length) {
        let lis = []
        content.li.forEach(li => {
          lis.push(<li key={count++}>{li}</li>);
        });
        elements.push(<ul key={count++}>{lis}</ul>);
      }
      return elements;
    }
}

export default Clouds