import React from "react";
import styled from "styled-components";
import {
  FONT_STYLE,
  SPACING,
  COLOR,
  KEYFRAMES,
  PATTERN,
  DEVICE
} from "../constants";
import data from "../content.json";

const Container = styled.div`
  // width: 35%;
  // min-width: 250px;
  width: auto;
  min-width: 110px;
  max-width: 240px;
  padding: 1em;
  border: 1px solid ${COLOR.SECONDARY};
  border-radius: ${SPACING.SMALL};
  margin-top: ${SPACING.LARGE};
  display: ${props => (props.visible ? "block" : "none")};
  position: absolute;
  animation: ${KEYFRAMES.FLIGHT} 6s linear infinite alternate;
  background: ${COLOR.BACKGROUND};
  bottom: 2vw;
  left: -10vw;
  z-index: 400;
  align-self: flex-end;
  margin: 0;

  &:after,
  &:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: ${COLOR.BACKGROUND};
    border-width: 16px;
    margin-top: -16px;
  }

  &:before {
    border-color: rgba(119, 119, 119, 0);
    border-left-color: ${COLOR.SECONDARY};
    border-width: 18px;
    margin-top: -18px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const Paragraph = styled.p`
  ${FONT_STYLE.DETAIL};
`;

const HelpLink = styled.a`
  ${FONT_STYLE.DETAIL};
  text-decoration: none;
  cursor: pointer;
  position: relative;
  margin-right: 20px;

  &::after {
    ${PATTERN.CHEVRON}
    bottom: 0;

    @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
      bottom: 0;
    }

    @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
      bottom: 1px;
    }

    @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
      bottom: 3px;
    }

    @media screen and (min-width: ${DEVICE.LAPTOP}) {
      bottom: 5px;
    }
  }

  &:hover {
    text-decoration: none;

    &::after {
      animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;
      bottom: 0;

      @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite alternate;
        bottom: 0;
      }

      @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite alternate;
        bottom: 1px;
      }

      @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
          alternate;
        bottom: 3px;
      }

      @media screen and (min-width: ${DEVICE.LAPTOP}) {
        animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
        bottom: 5px;
      }
    }
  }
`;

class HelperText extends React.Component {
  state = {
    isVisible: false,
    isLoaded: false,
    content: null,
    isHelp: true
  };

  loadContent = () => {
    if (this.props[this.props.current] === 0) {
      this.setState({
        isLoaded: true,
        isVisible: true,
        isHelp: true,
        content: data.shared.cta[1]
      });
    } else if (this.props[this.props.current] === 2) {
      this.setState({
        isLoaded: true,
        isVisible: true,
        isHelp: false,
        content: data[this.props.current].cta[0],
        link: data[this.props.current].cta[1]
      });
    }
  };

  componentDidMount() {
    this.loadContent();
  }

  componentDidUpdate(prevProps) {
    if (this.props[this.props.current] !== prevProps[prevProps.current]) {
      this.loadContent();
    }
  }

  render() {
    return (
      <Container visible={this.state.isVisible}>
        <ContentWrapper>
          {this.state.isHelp && <Paragraph>{this.state.content}</Paragraph>}
          {!this.state.isHelp && (
            <HelpLink
              href={this.state.link}
              target="_blank"
              onClick={() => {
                this.props.setOffsiteProgress(this.state.link)
                this.props.updateClickstream(this.state.link, this.state.content);
              }}
            >
              {this.state.content}
            </HelpLink>
          )}
        </ContentWrapper>
      </Container>
    );
  }
}

export default HelperText;
